import { toast } from "react-toastify";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { API_VIN_PIEZAS_SIDEGAL } from '../../views/Main/components/shared/services/vin_piezas_sidegal';

const initialVinPiezasSidegal = {allMarcas: [], allEstados:[]};

export const vinPiezasSidegalReducer = (state = initialVinPiezasSidegal, action) => {
    switch(action.type){
        case "vinPiezasSidegal/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncVinPiezasSidegal = makeAsyncTypes('vinPiezasSidegal');
const [setPending, setFullFilled, setError ] = asyncMac(asyncVinPiezasSidegal);

export const vinFetchingReducer = makeFetchingReducer([
    'vinPiezasSidegal/pending', 
    'vinPiezasSidegal/fullfilled', 
    'vinPiezasSidegal/rejected'
]);

export const fetchVinPiezasSidegal = () => async dispatch => {
    dispatch({type: 'loading/set', payload: true});
    dispatch(setPending());

    try {
        const response = await API_VIN_PIEZAS_SIDEGAL.getAllMarcas();
        const data = await response.data;
        dispatch(setFullFilled({
            allMarcas: data,
            allEstados: [
                { id: 1, name: 'Todos' },
                { id: 2, name: 'Pendientes' },
                { id: 3, name: 'Realizados' },
            ]
        }));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const vinPiezasSidegalStatusReducer = vinFetchingReducer