import API from '../../../../../services/api';

export const API_VIN_PIEZAS_SIDEGAL = {
    getAllMarcas: async () => {
        return await API.get('/vin-piezas-sidegal/allmarcas')
        .then(res => res.data)
    },
    getParts: async (data) => {
        return await API.post('/vin-piezas-sidegal/getparts', data)
        .then(res => res.data)
    }
};
