import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './autocompleteCustom.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown, faXmark } from '@fortawesome/free-solid-svg-icons';

const AutocompleteCustom = ({
    options,
    getOptionLabel,
    value,
    onChange,
    label,
    className = '',
    defaultValue,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options);

    const inputRef = useRef(null);

    useEffect(() => {
        if (value) {
            setInputValue(getOptionLabel(value));
        } else {
            setInputValue('');
        }
    }, [value, getOptionLabel]);

    useEffect(() => {
        const newOptions = options.filter((option) =>
            getOptionLabel(option)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
        );
        setFilteredOptions(newOptions);
    }, [inputValue, options, getOptionLabel, onChange]);

    const handleOptionClick = (option) => {
        onChange(option);
        setInputValue(getOptionLabel(option));
        setIsOpen(false);
    };

    const handleInputFocus = () => {
        setIsOpen(true);
    };

    const handleOutsideClick = (event) => {
        if (!inputRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleClearInput = () => {
        setInputValue('');
        onChange(null);
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (!value && defaultValue) {
            onChange(defaultValue);
        }
    }, [defaultValue, value, onChange]);

    return (
        <div className={`autocomplete ${className}`} ref={inputRef}>
            <div
                className={`input-wrapper ${
                    inputValue || isOpen ? 'focused' : ''
                }`}
            >
                {label && <label className="autocomplete-label">{label}</label>}
                <input
                    className="autocomplete-input"
                    onFocus={handleInputFocus}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
                {inputValue && (
                    <div className="clear-icon" onClick={handleClearInput}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                )}
                <div
                    className="autocomplete-icon"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
                </div>
            </div>
            {isOpen && filteredOptions.length > 0 && (
                <div className="autocomplete-list">
                    {filteredOptions.map((option, index) => (
                        <div
                            key={index}
                            className={`autocomplete-option ${
                                value && value?.id === option?.id
                                    ? 'selected'
                                    : ''
                            }`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {getOptionLabel(option)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

AutocompleteCustom.propTypes = {
    options: PropTypes.array,
    getOptionLabel: PropTypes.func,
    value: PropTypes.any,
    onChange: PropTypes.func,
    label: PropTypes.string,
    className: PropTypes.string,
    defaultValue: PropTypes.any,
};

export default AutocompleteCustom;
