import { useState } from 'react';
import '../Vin/vin.scss';
import './vinPiezasSidegal.scss';
import { statesData } from '../Vin/data';
import { toast } from 'react-toastify';
import { API_VIN } from '../shared/services/vin';

import VinTable from '../Vin/VinTable';
import InfoModal from '../shared/components/modals/Info';
import VinForm from '../Vin/VinForm';
import FilterSidegal from './FilterSidegal';
import Pagination from '@mui/material/Pagination';
import { API_VIN_PIEZAS_SIDEGAL } from '../shared/services/vin_piezas_sidegal';
import { vinSidegalTableData } from './data';
import { CircularProgress } from '@mui/material';

const VinPiezasSidegal = () => {
    const [allVinResults, setAllVinResults] = useState(null);
    const [vinSidegalData, setVinSidegalData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useState(null);
    const [filterBy, setFilterBy] = useState({
        name: '',
        value: '',
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentPart, setCurrentPart] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totals, setTotals] = useState({
        totalPages: 0,
        totalRecords: 0,
    });

    const handleViewMoreInfo = (partData) => {
        setCurrentPart(partData);
        setIsDialogOpen(true);
    };

    const handleEdit = (partData) => {
        setIsEdit(true);
        setCurrentPart(partData);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setCurrentPart(false);
    };

    const submitSearch = async (
        searchParams,
        newPage = 1,
        isFromPagination
    ) => {
        if (isLoading) return;
        if (!isFromPagination) {
            setIsLoading('search');
        } else {
            setIsLoading('pagination');
        }
        if (newPage === 1) setCurrentPage(1);
        setSearchParams(searchParams);
        try {
          const res = await API_VIN_PIEZAS_SIDEGAL.getParts({
              ...searchParams,
              page: newPage,
              limit: 50,
          });
          setAllVinResults(res.parts.data);
          setVinSidegalData(res.parts.data);
          return setTotals({
              totalPages: res.parts.totalPages,
              totalRecords: res.parts.totalRecords,
          });
        } catch (error) {
          toast.error('No se han podido obtener las piezas correctamente');
          setVinSidegalData([]);
          setAllVinResults([])
        } finally {
          setIsLoading(false)
        }
    };

    const handleSavePartData = async (newData) => {
        const response = await API_VIN.editPart({
            newData,
            partId: currentPart.id,
        });
        setIsDialogOpen(false);
        if (response && response.success) {
            toast.success(response.message);
            submitSearch(searchParams, currentPage, true);
        }
    };

    const handleOrderBy = (name) => {
        let value = 'asc';
        if (name === filterBy.name) {
            setFilterBy({
                ...filterBy,
                value: filterBy.value === 'asc' ? 'desc' : 'asc',
            });
            if (filterBy.value === 'asc') value = 'desc';
        } else {
            setFilterBy({
                name,
                value: 'asc',
            });
        }
        return submitFilters('orderBy', name, value);
    };

    const handleSearchBy = (name, value) => {
        let searchValue = value;

        if (value === '') {
            setVinSidegalData(allVinResults);
            setFilterBy({
                name,
                value,
            });
            return;
        }

        if (name === 'estado') {
            const dataValue = statesData.find((state) =>
                state['nom'].includes(value.toLowerCase())
            );
            if (dataValue) searchValue = dataValue.estpie_cod;
        }

        setFilterBy({
            name,
            value,
        });
        submitFilters('searchBy', name, searchValue);
    };

    const submitFilters = (typeOfFilter, name, value) => {
        let filteredArr;
        if (typeOfFilter === 'orderBy') {
            value === 'asc'
                ? (filteredArr = [...vinSidegalData].sort((a, b) => {
                      if (
                          name === 'id' ||
                          name === 'precio' ||
                          name === 'estado'
                      ) {
                          if (a[name] > b[name]) return 1;
                          if (a[name] < b[name]) return -1;
                          return 0;
                      } else {
                          return -a[name]?.localeCompare(b[name]);
                      }
                  }))
                : (filteredArr = [...vinSidegalData].sort((a, b) => {
                      if (
                          name === 'id' ||
                          name === 'precio' ||
                          name === 'estado'
                      ) {
                          if (a[name] > b[name]) return -1;
                          if (a[name] < b[name]) return 1;
                          return 0;
                      } else {
                          return -b[name]?.localeCompare(a[name]);
                      }
                  }));
        }
        if (typeOfFilter === 'searchBy') {
            filteredArr = [...vinSidegalData].filter(
                (item) =>
                    item[name] &&
                    item[name]
                        .toString()
                        .toLowerCase()
                        .includes(value.toString().toLowerCase())
            );
        }
        setVinSidegalData(filteredArr);
    };

    const resetFilters = (_, name = '') => {
        setFilterBy({
            name,
            value: '',
        });
        setVinSidegalData(allVinResults);
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        submitSearch(searchParams, newPage, true);
    };

    return (
        <div className="content pt-3 vin vin-piezas-sidegal">
            <h1 className="d-flex gap-2">
                Piezas Sidegal{' '}
                {totals.totalRecords > 0 && (
                    <p className="total-piezas-results">
                        ({totals.totalRecords} Piezas)
                    </p>
                )}
            </h1>

            <div className="d-flex flex-row align-items-center justify-content-between w-100">
                <FilterSidegal
                    onSearch={submitSearch}
                    isLoading={isLoading === 'search'}
                />
                {vinSidegalData && vinSidegalData.length > 0 && (
                    <div className='w-100 d-flex align-items-center justify-content-end gap-3'>
                        {isLoading === 'pagination' && <CircularProgress color="inherit" size="20px" />}
                        <Pagination
                            count={totals.totalPages}
                            page={currentPage}
                            onChange={handleChangePage}
                        />
                    </div>
                )}
            </div>

            {vinSidegalData && (
                <VinTable
                    data={vinSidegalData}
                    filterBy={filterBy}
                    onOrderBy={handleOrderBy}
                    onFilterBy={handleSearchBy}
                    onReset={resetFilters}
                    onEdit={handleEdit}
                    onViewMoreInfo={handleViewMoreInfo}
                    columnData={vinSidegalTableData}
                />
            )}

            {isDialogOpen && (
                <InfoModal
                    title={`Información #${currentPart.id} ${currentPart.Articulo} `}
                    content={
                        <VinForm
                            piezaInfo={currentPart}
                            isEdit={isEdit}
                            onSubmit={handleSavePartData}
                        />
                    }
                    state={isDialogOpen}
                    width={'md'}
                    onClose={handleCloseDialog}
                />
            )}
        </div>
    );
};

export default VinPiezasSidegal;
