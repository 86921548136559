import { useEffect, useState } from 'react';
import AutocompleteCustom from '../../shared/components/AutocompleteCustom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVinPiezasSidegal } from '../../../../../redux/features/vinPiezasSidegal';
import { CircularProgress } from '@mui/material';

const FilterSidegal = ({ onSearch, isLoading }) => {
    const dispatch = useDispatch();
    const { allEstados, allMarcas } = useSelector((state) => {
        return state.vin_piezas_sidegal.entity;
    });
    const [estado, setEstado] = useState(null);
    const [marca, setMarca] = useState(null);

    const handleSearch = async () => {
        let searchParams = {};
        if (estado && estado.name !== 'Todos') {
            searchParams.pendientes = estado.name === 'Pendientes' ? 1 : 0;
        }
        if (marca) {
            searchParams.idMarca = Number(marca.idMarca);
        }
        await onSearch(searchParams);
    };

    useEffect(() => {
        if (allEstados?.length === 0 && allMarcas?.length === 0) {
            dispatch(fetchVinPiezasSidegal());
        }
    }, []);

    return (
        <div className="w-100 d-flex align-items-center my-4 filter-sidegal">
            <AutocompleteCustom
                options={allEstados}
                getOptionLabel={(option) => option.name}
                value={estado}
                onChange={(newValue) => setEstado(newValue)}
                label="Estado"
                defaultValue={estado}
            />

            <AutocompleteCustom
                options={allMarcas}
                getOptionLabel={(option) => option.marca}
                value={marca}
                onChange={(newValue) => setMarca(newValue)}
                label="Marca"
                defaultValue={marca}
            />

            <button
                className="btn btn-primary search-btn"
                onClick={handleSearch}
                disabled={!estado && !marca}
            >
                {isLoading ? <CircularProgress color="inherit" size="20px" /> : 'Buscar'}
            </button>
        </div>
    );
};

export default FilterSidegal;
