
export const vinSidegalTableData = [
  {
    title: 'Id',
    value: 'id',
    xs: 12,
    sm: 6,
    md: 4,
    edit: false
  },
  {
    title: 'Pieza',
    value: 'Articulo',
    xs: 12,
    sm: 6,
    md: 4,
    edit: false
  },
  {
    title: 'Referencia',
    value: 'ref1',
    xs: 12,
    sm: 6,
    md: 4,
    edit: true
  },
  {
    title: 'Ref 2',
    value: 'ref2',
    xs: 12,
    sm: 6,
    md: 4,
    edit: true
  },
  {
    title: 'Ref 3',
    value: 'ref3',
    xs: 12,
    sm: 6,
    md: 4,
    edit: true
  },
  {
    title: 'Precio',
    value: 'precio',
    xs: 12,
    sm: 6,
    md: 4,
    edit: true
  },
  {
    title: 'Ubicación',
    value: 'ubica',
    xs: 12,
    sm: 6,
    md: 4,
    edit: false
  },
  {
    title: 'Obs. Púb.',
    value: 'obspub',
    xs: 12,
    sm: 12,
    md: 12,
    edit: true
  },
  {
    title: 'Obs. Priv.',
    value: 'obspri',
    xs: 12,
    sm: 12,
    md: 12,
    edit: true
  }
]



